<template>
  <div class="outer-wrapper">
    <div class="one-top-wrapper" v-if="content.length">
      <div class="line-label">
        <Icon type="md-subway" /><span class="city-title-tag">{{
          content[0].city
        }}</span
        ><span class="line-title-tag" :style="{color:content[0].line_color}">{{ content[0].line_name }}</span>
      </div>
      <div class="one-top">
        <div
          v-for="(ele, index) in content"
          class="description-wrapper"
          :key="index"
        >
        <div class="inner-wrapper">
          <a :href="downloadUrl(ele.file_url)"
            ><img :src="imgUrl" class="pdf-img" />
            <p class="item-name">
              {{ ele.evaluate_time }}年{{ ele.report_type }}
            </p>
            <p>
              <span class="download-button">点击下载</span>
              </p>
            </a
          >
          </div>
        </div>
      </div>
    </div>
    <div class="none-report" v-else>
      暂无报告
    </div>
    <div class="down-wrapper">
      <div class="down-inner-wrapper"></div>
    </div>
  </div>
</template>
<script>
let userToken = localStorage.getItem("current_user_token");
let authData = JSON.parse(localStorage.getItem("authData"));

export default {
  name: "reportContent",
  components: {},
  data() {
    return {
      content: [],
      ut: userToken,
      imgUrl: process.env.BASE_URL + "img/pdf.jpg",
    };
  },
  props: {
    report_id: {
      type: String,
      default: "1",
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.get_data(to.params.report_id);
    next();
  },
  created() {
    this.get_data();
  },
  filters:{
    
  },
  methods: {
    downloadUrl (params) {
      return this.$fileUrlFac(params,authData.sfileRight);
    },
    get_data(id = null) {
      if (id === null) {
        id = this.report_id;
      }
      this.$axios
        .post(this.$url + "/report_content", {
          report_id: id,
          userToken: userToken,
        })
        .then((response) => {this.content = response.data;});
    },
  },
};
</script>
<style scoped>
.outer-wrapper {
  min-height: 500px;
}
.one-top {
  box-shadow: 2px 2px 1px #aaa;
  display:flex;
  -webkit-display:flex;
  flex-wrap: wrap;
  justify-content:center;
  border: 1px solid #aaa;
  overflow: hidden;
  padding: 100px 10px;
  min-height: 500px;
}
.one-top-wrapper {
  position: relative;
  padding: 20px;
}
.line-label {
  position: absolute;
  top: 2px;
  left: 60px;
  background: #fff;
  color: #333;
  font-size: 24px;
  font-weight: 700;
  padding: 0 20px;
}

.city-title-tag {
  font-family: "LiSu";
  margin: 0 10px;
}

.line-title-tag {
  font-family: "LiSu";
  padding: 0 5px;
}
.title-text-wrapper {
  border: 1px solid #dad9d9;
  font-size: 20px;
  width: 300px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 10px 30px;
  border-radius: 5px;
  color: #777;
  background-color: #f7f7f7;
  cursor: pointer;
}

.title-text-wrapper:hover {
  box-shadow: 2px 2px 5px #acabab;
}

.title-wrapper {
  height: 100px;
  text-align: center;
}
.city-text-wrapper {
  padding-right: 10px;
  font-size: 20px;
  font-weight: 400;
  color: #5a5a5a;
  font-family: STLiti;
  margin-right: 20px;
}

.description-wrapper {
  width:220px;
  text-align: center;
  padding: 20px;
  font-size: 20px;
}

.inner-wrapper{
  border: 1px solid #993333;
  padding: 20px;
  border-radius: 3px;
}

.pdf-img{
  width:140px;
}

.item-name{
  height:70px;
  font-size:18px;
  text-align: center;
}

.download-button{
  background-color: #993333;
  padding: 5px 20px;
  color:#fff;
  font-size:14px;
  border-radius: 3px;
}

.none-report{
  text-align: center;
  padding-top:40px;
  font-size: 20px;
}
</style>